import {
  ComponentType,
  createContext,
  useContext,
  useReducer,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ProjectCreation } from "../pages/projects/create/CreateProject";
import { ProjectWithCalendlyData } from "../pages/projects/overview/ProjectOverview";
import { getCurrentUser, User } from "../services/auth";

type State = {
  user: User | null;
  projectInCreation: ProjectCreation | null;
  projects: ProjectWithCalendlyData[];
};

const initialState: State = {
  user: null,
  projectInCreation: null,
  projects: [],
};

enum ActionType {
  SET_USER,
  SET_PROJECT_IN_CREATION,
  SET_PROJECTS,
}

type Action =
  | { type: ActionType.SET_USER; user: User | null }
  | {
      type: ActionType.SET_PROJECT_IN_CREATION;
      project: ProjectCreation | null;
    }
  | { type: ActionType.SET_PROJECTS; projects: ProjectWithCalendlyData[] };

type Actions = {
  setUser: (user: User | null) => void;
  setProjectInCreation: (project: ProjectCreation | null) => void;
  setProjects: (projects: ProjectWithCalendlyData[]) => void;
};

const StateContext = createContext<[State, Actions]>([
  initialState,
  {
    setUser: () => {},
    setProjectInCreation: () => {},
    setProjects: () => {},
  },
]);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case ActionType.SET_PROJECT_IN_CREATION:
      return {
        ...state,
        projectInCreation: action.project,
      };
    case ActionType.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    default:
      return state;
  }
};

type StateProviderProps = { children: React.ReactNode };
export const StateProvider: ComponentType<StateProviderProps> = ({
  children,
}: StateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setloading] = useState(true);

  const actions = useMemo(() => {
    return {
      setUser: (user: User | null) => {
        dispatch({
          type: ActionType.SET_USER,
          user,
        });
      },
      setProjects: (projects: ProjectWithCalendlyData[]) => {
        const stringifiedProjects = JSON.stringify(projects);
        localStorage.setItem("projects", stringifiedProjects);

        dispatch({
          type: ActionType.SET_PROJECTS,
          projects,
        });
      },
      setProjectInCreation: (project: ProjectCreation | null) => {
        dispatch({
          type: ActionType.SET_PROJECT_IN_CREATION,
          project,
        });
      },
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const user = await getCurrentUser();
      const stringifiedProjects = localStorage.getItem("projects");

      try {
        if (user) {
          actions.setUser(user);
        } else {
          actions.setUser(null);
        }

        if (stringifiedProjects) {
          const projects = JSON.parse(stringifiedProjects);
          actions.setProjects(projects);
        }
      } catch (error) {
        console.error(error);
      }

      setloading(false);
    })();
  }, [actions]);

  return (
    <StateContext.Provider value={[state, actions]}>
      {loading ? null : children}
    </StateContext.Provider>
  );
};

export const useGlobalState: () => [State, Actions] = () => {
  return useContext(StateContext);
};
