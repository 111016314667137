import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";

export default function MatomoTracking() {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView(undefined);
  }, []);

  return <></>;
}
