import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { Amplify } from "aws-amplify";

// user as we get it from cognito
// they're some keys missing in the type definition. So we're adding them here ourself
export interface CustomCognitoUser extends CognitoUser {
  attributes?: { [key: string]: string };
}

export type RawUserAttributes = {
  sub: string;
  email_verified: string;
  "custom:receive_newsletter": string;
  name: string;
  locale: string;
  email: string;
};

export type UserAttributes = {
  email?: string;
  name?: string;
  receiveNewsletter?: string;
  locale: string;
  sub: string;
};

// transformed user as we want it to use in the frontend
export type User = CustomCognitoUser & {
  attributes: UserAttributes;
  username: string;
  isVerified?: boolean;
};

// transform cognito
const transformUser: (rawUser: CustomCognitoUser) => User = (rawUser) => {
  const user = Object.assign(rawUser);
  const rawUserAttributes = rawUser.attributes || {};
  user.username = rawUser.getUsername();

  const tmpRawAttributes = {
    ...rawUserAttributes,
    ...{
      receiveNewsletter:
        rawUserAttributes["custom:receive_newsletter"] === "true"
          ? true
          : false,
    },
  };

  user.attributes = Object.fromEntries(
    Object.entries(tmpRawAttributes).map(([key, value]) => [
      key.replace(/^custom:/, ""),
      value,
    ]),
  );
  delete user.attributes["receive_newsletter"];
  return user;
};

export const initializeAmplify = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID,
      region: process.env.NEXT_PUBLIC_AWS_REGION,
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        region: process.env.NEXT_PUBLIC_AWS_REGION,
        bucket: process.env.NEXT_PUBLIC_S3_BUCKET_NAME,
      },
    },
  });
};

export const signin: (
  email: string,
  password: string,
) => Promise<User> = async (email, password) => {
  const user: CustomCognitoUser = await Auth.signIn(
    email.toLowerCase(),
    password,
  );
  return transformUser(user);
};

export const signup: (
  email: string,
  password: string,
  attributes: {
    name: string;
    locale: string;
  },
) => Promise<User> = async (email, password, attributes) => {
  const result = await Auth.signUp({
    username: email.toLowerCase(),
    password,
    attributes: {
      email: email.toLowerCase(),
      name: attributes.name,
      "custom:receive_newsletter": "false",
      locale: attributes.locale,
    },
  });

  return transformUser(result.user);
};

export const confirmVerificationCode = (username: string, code: string) => {
  return Auth.confirmSignUp(username, code);
};

export const verifyCodeAndSignin: (
  username: string,
  password: string,
  code: string,
) => Promise<User> = async (username, password, code) => {
  await Auth.confirmSignUp(username, code);
  return signin(username, password);
};

export const signout: () => Promise<void> = async () => {
  return Auth.signOut();
};

export const forgotPassword: (email: string) => Promise<void> = async (
  email,
) => {
  return Auth.forgotPassword(email.toLowerCase());
};

export const forgotPasswordSubmit: (
  email: string,
  code: string,
  newPassword: string,
) => Promise<string> = async (email, code, newPassword) => {
  return Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
};

export const isUserSignedIn: () => Promise<boolean> = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

export const getCurrentUser: () => Promise<User | null> = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return transformUser(user);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserApiAccessToken: () => Promise<
  string | undefined
> = async () => {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  // Amplify automatically refreshes id and accessToken in case they're expired
  return user.getSignInUserSession()?.getAccessToken().getJwtToken();
};

export const deleteUser = () => {
  return Auth.deleteUser();
};

export const updateUser: (
  attributes: Partial<RawUserAttributes>,
) => Promise<User> = async (attributes) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, attributes);
  const updatedUser = await Auth.currentAuthenticatedUser();
  return transformUser(updatedUser);
};

// Cannot be run in Middleware as of now.
// verifier.verify throws a "window is not defined" bug in edge runtime: https://github.com/aws-amplify/amplify-js/issues/6813
// next middleware runtime cannot be changed to nextjs as of now: https://github.com/vercel/next.js/discussions/34179
export const verifyCognitoAccessToken = async (
  accessToken: string | undefined,
) => {
  if (!accessToken) return false;

  const verifier = CognitoJwtVerifier.create({
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID as string,
    tokenUse: "access",
    clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
  });

  const { sub } = await verifier.verify(accessToken);
  return sub;
};

export const getUserIdentityId: () => Promise<string> = async () => {
  const { identityId } = await Auth.currentUserCredentials();
  return identityId;
};
