import "../styles/global.scss";
import type { AppProps } from "next/app";
import "reflect-metadata";
import { StateProvider } from "../context/State";
import { appWithTranslation } from "next-i18next";
import { updateLocale } from "moment";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { initializeAmplify } from "../services/auth";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import MatomoTracking from "components/_atoms/MatomoTracking/MatomoTracking";

initializeAmplify();

const instance = createInstance({
  urlBase: "https://util.pina.earth",
  siteId: 2,
  disabled: false,
  heartBeat: {
    active: true,
    seconds: 10,
  },
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

const App = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter();

  // translate moment.js AM/PM for german
  useEffect(() => {
    if (!locale) return;

    locale === "de"
      ? updateLocale(locale, {
          meridiem: () => " Uhr",
        })
      : updateLocale(locale, {
          meridiem: (hours) => (hours < 12 ? "am" : "pm"),
        });
  }, [locale]);

  return (
    <MatomoProvider value={instance}>
      <MatomoTracking />
      <StateProvider>
        <Component {...pageProps} />
      </StateProvider>
    </MatomoProvider>
  );
};

export default appWithTranslation(App);
